///include /assets/js/app/s/shared-settings.js
"use strict"

class VueFooter {
    /**
     *
     * @param {boolean} should_be_authenticated
     */
    init(should_be_authenticated) {
        this.view = new Vue({
            el: '#vue-footer',
            name: 'footer',
            data() {
                return {
                    brand: brand,
                    settings: SharedSettings.inst,
                    get isExplicitStackUser () {
                        // is this.username && this.username.match(/@/) a better way to do this?
                        return this.settings.user?.contact?.email;
                    },
                }
            }
        });
    }
}
